
@font-face {
  font-family: 'Futura';
  src: url(./Futura-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
@font-face {
  font-family: 'Futura';
  src: url(./Futura-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: 'Futura';
  src: url(./Futura-Light.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
}
@font-face {
  font-family: 'Futura';
  src: url(./Futura-Bold-Condensed.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Futura';
  src: url(./Futura-Light-Condensed.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
  font-stretch: condensed;
}
@font-face {
  font-family: 'Font-Icon';
  src: url(./every_frm_font.ttf) format('truetype');
}
@font-face {
  font-family: 'Segoe UI';
  src: url(./segoe-ui.ttf) format('truetype');
  font-style: normal;
  font-weight: 200;
  font-stretch: condensed;
}

@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');